<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">PURCHASE</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex column gaps mt-30">
        <div class="box grow flex gaps ">
                <el-select
                  v-model="search"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Please select an item"
                  @change="handleSelect"
                  :remote-method="remoteMethod">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            <div class="box grow"></div>
        </div>
        <el-divider class="mv-10 o-020"></el-divider>
        <div class="box grow flex gaps " v-if="isSelected">
            <div class="box grow">
                <el-form label-position="left" label-width="160px" :model="item" size="small">
                    <el-form-item label="Name">
                        <el-input v-model="item.name" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Description">
                        <el-input v-model="item.description" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Barcode">
                        <el-input v-model="item.barcode" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Company">
                        <el-input :value="filterCompany(item.company)" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Category">
                        <el-input :value="filterCategory(item.category)" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Price">
                        <el-input v-model="item.cost" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Is Rent">
                        <el-checkbox v-model="item.isRent" :disabled="true"></el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            <div class="box grow flex top gaps">
                <el-form label-position="right" :rules="rules" ref="form" label-width="160px" :model="form">
                    <el-form-item prop="amount">
                        <label slot="label">
                            <div class="form-label">Amount</div>
                        </label>
                        <el-input-number :min="0" :controls="false" v-model="form.amount"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="amount">
                        <label slot="label">
                            <div class="form-label">Price for unit</div>
                        </label>
                        <el-input-number :min="0" :controls="false" v-model="form.price"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="amount">
                        <label slot="label">
                            <div class="form-label">Date</div>
                        </label>
                            <el-date-picker
                              :append-to-body="false"
                              v-model="form.date"
                              type="date"
                              placeholder="Pick a day">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit('form')">Submit</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'Purchase',

  data () {
    var checkAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Please input the amount'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('Please input digits'))
      } else {
        if (value < 1) {
          callback(new Error('Amount must be greater than 0'))
        } else {
          callback()
        }
      }
    }
    return {
      rules: {
        amount: [
          { validator: checkAmount, trigger: 'blur' }
        ]
      },
      dataItems: [],
      search: '',
      item: {
        name: '',
        description: '',
        category: '',
        company: '',
        barcode: '',
        cost: '',
        isRent: '',
        isAddon: ''
      },
      buffer: {
        date: -1,
        text: ''
      },
      form: {
        amount: 1,
        price: 0,
        date: Date.now()
      },
      count: 1,
      isSelected: false,
      options: []
    }
  },

  computed: {
    ...mapState({
      warehouse: state => state.warehouse.warehouse
    }),
    ...mapGetters([
      // 'items',
      'getCompanyNameById',
      'getCategoryNameById',
      'getItemById'
    ])
  },

  methods: {
    ...mapActions(['addTransaction']),
    remoteMethod (query) {
      if (query !== '' && query.length >= 2) {
        this.options = this.warehouse.filter(item => {
          return item.name.toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        }).map(i => {
          return {
            value: i._id,
            label: i.name
          }
        })
      } else {
        this.options = this.clearOption
      }
    },
    filterCategory: function (value) {
      return this.getCategoryNameById[value]
    },
    filterCompany: function (value) {
      return this.getCompanyNameById[value]
    },
    handleSelect (id) {
      this.item = this.getItemById[id]
      this.isSelected = true
    },
    keyDown: function (e) {
      const today = new Date()
      if (this.buffer.text === '') {
        this.buffer.date = today.getTime()
        this.buffer.text = this.buffer.text + '' + e.key
      } else if (e.key === 'ArrowDown' && this.buffer.text.slice(-5) === 'Enter') {
        this.scanBarCode(this.buffer.text.slice(0, -5))
        Object.assign(this.buffer, {
          date: -1,
          text: ''
        })
      } else if (this.buffer.date > today.getTime() - 1000) {
        this.buffer.text = this.buffer.text + '' + e.key
      } else {
        Object.assign(this.buffer, {
          date: today.getTime(),
          text: e.key
        })
      }
    },
    scanBarCode (text) {
      let found = this.dataItems.filter(el => el.barcode === text)
      this.item = found.length === 1 ? found[0] : {
        name: '',
        description: '',
        category: '',
        company: '',
        barcode: '',
        cost: '',
        isRent: '',
        isAddon: '',
        count: ''
      }
      this.search = this.item.name
      this.isSelected = true
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const model = {
            name: this.item.name,
            itemId: this.item._id,
            count: this.form.amount,
            date: this.form.date,
            price: this.form.price,
            eventType: 'purchase',
            memo: ''
          }
          this.addTransaction(model)
            .then(() => {
              this.$message({
                showClose: true,
                message: this.form.amount + ' ' + this.item.name + ' addition record created',
                type: 'success'
              })
            })
            .then(() => {
              this.search = ''
              this.item = {
                name: '',
                description: '',
                category: '',
                company: '',
                barcode: '',
                cost: '',
                isRent: '',
                isAddon: '',
                count: ''
              }
              this.buffer = {
                date: -1,
                text: ''
              }
              this.form = {
                amount: 1,
                price: 0,
                date: Date.now()
              }
              this.isSelected = false
            })
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    this.dataItems = this.warehouse
  },
  created: function () {
    window.addEventListener('keydown', this.keyDown)
  },

  destroyed: function () {
    window.removeEventListener('keydown', this.keyDown)
  }
}
</script>
